import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CoreModule } from 'src/app/core.module';
import { Balance } from 'src/app/core/models/balance';
import { VacationType } from 'src/app/core/models/vacation-type';
import { BalanceFacade } from 'src/app/core/store/balance/balance.facade';
import { BalanceHolder, CustomBalance } from '../settings/balance/IBalance';
import { VacationTypeFacade } from 'src/app/core/store/vacation-type/vacation-type.facade';
import { DateTime } from 'luxon';
import {
  ColumnDef,
  ContextMenuAction,
  MicrosoftAuthenticationService,
} from 'processdelight-angular-components';
import { vartranslations$ } from 'src/app/core/services/startup.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  standalone: true,
  selector: 'app-saldo',
  templateUrl: './saldo.component.html',
  styleUrls: ['./saldo.component.scss'],
  imports: [CoreModule],
})
export class SaldoComponent implements OnInit, OnDestroy {
  translations = vartranslations$.value!;
  destroy$ = new Subject<void>();
  balances$ = this.balanceFacade.balances$;
  balances: Balance[] = [];
  vacationTypes$ = this.vacationTypeFacade.vacationTypes$;
  vacationtypes: VacationType[] = [];
  balancesForEmployee: BalanceHolder[] = [];
  possibleColumns: ColumnDef<CustomBalance>[] = [
    new ColumnDef({
      internalName: 'vacationtype',
      displayName: this.translations.vacationType,
      sortable: true,
      valueAccessor: (balance) => balance.balances[0].vacationType?.name,
      canChangeWidth: true,
    }),
    new ColumnDef({
      internalName: 'quantity',
      displayName: this.translations.quantity,
      sortable: true,
      valueAccessor: (balance) => balance.balances[0].quantity || '-',
      canChangeWidth: true,
    }),
    new ColumnDef({
      internalName: 'saldoInApproval',
      displayName: this.translations.saldoInApproval,
      sortable: true,
      valueAccessor: (balance) => balance.balances[0].saldoInApproval || '-',
      canChangeWidth: true,
    }),
    new ColumnDef({
      internalName: 'saldoApproved',
      displayName: this.translations.saldoApproved,
      sortable: true,
      valueAccessor: (balance) => balance.balances[0].saldoApproved || '-',
      canChangeWidth: true,
    }),
    new ColumnDef({
      internalName: 'saldoUsed',
      displayName: this.translations.used,
      sortable: true,
      valueAccessor: (balance) => balance.balances[0].used || '-',
      canChangeWidth: true,
    }),
    new ColumnDef({
      internalName: 'startDate',
      displayName: this.translations.startDate,
      sortable: true,
      valueAccessor: (balance) => balance.balances[0].startDate || '-',
      canChangeWidth: true,
    }),
    new ColumnDef({
      internalName: 'endDate',
      displayName: this.translations.endDate,
      sortable: true,
      valueAccessor: (balance) => balance.balances[0].endDate || '-',
      canChangeWidth: true,
    }),
  ];
  displayedColumns: string[] = [
    'vacationtype',
    'quantity',
    'saldoInApproval',
    'saldoApproved',
    'saldoUsed',
    'startDate',
    'endDate',
  ];
  sortDirection: '' | 'asc' | 'desc' = '';
  sortColumn = '';
  iconActions: ContextMenuAction<unknown>[] = [];

  constructor(
    private balanceFacade: BalanceFacade,
    private vacationTypeFacade: VacationTypeFacade,
    private msal: MicrosoftAuthenticationService,
    public dialogRef: MatDialogRef<SaldoComponent>
  ) {}

  ngOnInit(): void {
    this.balances$.pipe(takeUntil(this.destroy$)).subscribe((items) => {
      this.balances = items || [];
      this.balancesForEmployee = this.balances
        .filter(
          (balance) =>
            balance.user &&
            balance.year === DateTime.now().year &&
            balance.user?.user?.id === this.msal.userId
        )
        .map(
          (balance) =>
            new BalanceHolder({
              user: balance.user,
              balances: [balance],
              collapsed: false,
            })
        );

      this.balancesForEmployee.forEach((balances) => {
        balances.balances.forEach((balance) => {
          if (balance.quantity && balance.quantity === '') {
            if (Number.parseFloat(balance.quantity) < 1) balance.quantity = '-';
          }
          if (balance.saldoInApproval && balance.saldoInApproval === '') {
            if (Number.parseFloat(balance.saldoInApproval) < 1) {
              balance.saldoInApproval = '-';
            }
          }
          if (balance.saldoApproved && balance.saldoApproved === '') {
            if (Number.parseFloat(balance.saldoApproved) < 1) {
              balance.saldoApproved = '-';
            }
          }
          if (balance.used && balance.used === '') {
            if (Number.parseFloat(balance.used) < 1) balance.used = '-';
          }
        });
      });
    });
    this.balanceFacade.getBalances$();

    this.vacationTypes$.pipe(takeUntil(this.destroy$)).subscribe((items) => {
      this.vacationtypes = items || [];
    });
    this.vacationTypeFacade.getVacationTypes$();

    this.iconActions.push(
      new ContextMenuAction({
        label: 'close',
        icon: 'close',
        iconOutline: true,
        isSymbol: true,
        action: () => this.dialogRef.close(),
      })
    );
  }

  sortChanged(event: {
    sortedColumn: string;
    sortDirection: '' | 'asc' | 'desc';
  }) {
    this.sortDirection = event.sortDirection;
    this.sortColumn = event.sortedColumn;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
