import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarOoOService } from '../../services/ooo.service';
import {
  addVacationTypes,
  addVacationTypesResolved,
  getVacationTypes,
  getVacationTypesResolved,
  removeVacationTypes,
  removeVacationTypesResolved,
  updateVacationTypes,
  updateVacationTypesResolved,
} from './vacation-type.actions';
import { call } from '@microsoft/teams-js';

@Injectable({ providedIn: 'root' })
export class VacationTypeEffects {
  constructor(
    private actions$: Actions,
    private ishtarOoOService: IshtarOoOService
  ) {}

  getVacationTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(getVacationTypes),
      switchMap(({ callback }) =>
        this.ishtarOoOService.getVacationTypes().pipe(
          switchMap((vacationTypes) =>
            of(
              getVacationTypesResolved({
                vacationTypes,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addVacationTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(addVacationTypes),
      switchMap(({ vacationTypes , callback}) =>
        this.ishtarOoOService.addVacationTypes(vacationTypes).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedVacationTypes) =>
            of(addVacationTypesResolved({ addedVacationTypes }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  updateVacationTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(updateVacationTypes),
      switchMap(({ vacationTypes }) =>
        this.ishtarOoOService.updateVacationTypes(vacationTypes).pipe(
          switchMap((updatedVacationTypes) =>
            of(updateVacationTypesResolved({ updatedVacationTypes }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  removeVacationTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(removeVacationTypes),
      switchMap(({ ids, callback }) =>
        this.ishtarOoOService.removeVacationTypes(ids).pipe(
          switchMap((ishtarVacationTypeIds) =>
            of(removeVacationTypesResolved({ ishtarVacationTypeIds }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );
}
