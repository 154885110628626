import { Action, createReducer, on } from '@ngrx/store';
import { Balance } from '../../models/balance';
import { AppState } from '../../../app.reducer';
import {
    getBalances,
    getBalancesResolved,
    updateBalances,
    updateBalancesResolved,
} from './balance.actions';

export const featureSlice = 'balance';

export interface State {
  balances: Balance[];
  balance?: Balance;
  ishtarSaldoIds?: string[];
}
const defaultState: State = {
    balances: [],
    balance: undefined,
    ishtarSaldoIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return balanceReducer(state, action);
}

export const initialState: State = defaultState;

export const balanceReducer = createReducer(
  initialState,
  on(getBalancesResolved, (state, { balances }) => ({
    ...state,
    balances: [...balances],
  })),
  on(updateBalancesResolved, (state, { updatedBalances }) => ({
    ...state,
    balances: state.balances.map(
      (s) =>
        updatedBalances.find(
          (u) => s.ishtarSaldoId === u.ishtarSaldoId
        ) ?? s
    ),
  })),
);

export const balanceState = (state: AppState) =>
  state.coreFeature.balance;
