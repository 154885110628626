import { GroupUser } from 'processdelight-angular-components';

export class Balance {
  ishtarSaldoId!: string;
  // user?: {id: string, name: string};
  user?: GroupUser;
  vacationType?: { id: string; name?: string };
  quantity?: string;
  saldoInApproval?: string;
  saldoApproved?: string;
  year?: number;
  used?: string;
  startDate?: string;
  endDate?: string;

  constructor(obj: Partial<Balance>) {
    Object.assign(this, obj);
  }
}
