import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApplicationNotification,
  Lookup,
  MicrosoftAuthenticationService,
} from 'processdelight-angular-components';
import { environment } from 'src/environments/environment';
import { UserLicenseInfo } from '../models/user/userlicenseinfo';
import { AppInfo } from '../models/user/appInfo';
import { varlicense$, varnotificationtriggertypes$ } from './startup.service';
import { GroupUser } from '../models/user/groupuser';
import { map } from 'rxjs';
import { AADUser } from '../models/user/aadUser';
import { Status } from '../models/status';
import { VacationSubtype } from '../models/vacation-subtype';
import { MeasureType } from '../models/measure-type';
import { VacationType } from '../models/vacation-type';
import { TeamCalendar } from '../models/teamCalendar';
import { VacationTypetranslation } from '../models/vacation-typeTranslation';
import { Language } from '../models/language';
import { Balance } from '../models/balance';
import {
  getEmailTemplates,
  updateEmailTemplates,
} from '../store/email-template/email-template.actions';
import { EmailTemplate } from '../models/email-template';
import { RequestConfiguration } from '../models/requestConfiguration';
import { getRequestConfiguration } from '../store/requestConfiguration/requestConfiguration.actions';
import { CreateNotificationrequest } from '../models/createNotificationRequest';
import { WorkRegimeDay } from '../models/workRegimeDay';

@Injectable({
  providedIn: 'root',
})
export class IshtarOoOService {
  addVacationSubtypes(vacationSubtypes: any) {
    throw new Error('Method not implemented.');
  }
  apiBase = `${environment.ishtarFunctions}/api`;
  constructor(
    private httpClient: HttpClient,
    private msal: MicrosoftAuthenticationService
  ) {}

  private createApiEndpointUrl(path: string) {
    const url = new URL(`${this.apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }

  filterQuery(filters: [string, any][]) {
    if (filters.length > 0) {
      const filterString =
        '&expand=Client,Status,ProjectType&select=*,Client,Status,ProjectType&filter=';
      return (
        filterString +
        filters
          .map((filter) => `('${filter[1]}' in ${filter[0]})`)
          .join(' and ')
      );
    } else {
      return '';
    }
  }
  orderByQuery(column: string, direction: string) {
    if (!column || !direction) return '';
    else return `&orderBy=${column} ${direction.toUpperCase()}`;
  }

  getLicense() {
    return this.httpClient.get<UserLicenseInfo>(
      this.createApiEndpointUrl(
        `license/${this.msal.tenantId}/${this.msal.email}`
      )
    );
  }

  getAppInfo() {
    return this.httpClient.get<AppInfo>(
      this.createApiEndpointUrl(`apps/Ishtar.OoO`)
    );
  }

  getRequestConfiguration() {
    return this.httpClient.get<RequestConfiguration>(
      this.createApiEndpointUrl(`apps/Ishtar.OoO/RequestConfiguration`)
    );
  }

  // updateRequestConfiguration(requestConfiguration: RequestConfiguration){
  //   const updatedRequestConfiguration = requestConfiguration.map((r) =>
  //   this.capitalizeKeys({...r})
  //   );
  //   return this.httpClient
  //   .patch<RequestConfiguration>(
  //     this.createApiEndpointUrl(
  //       `apps/Ishtar.OoO/RequestConfiguration`
  //   ),
  //   updatedRequestConfiguration,
  //   {
  //     headers: {
  //       ImpersonationUserAADId: varlicense$.value!.microsoftId,
  //     },f
  //   }
  //   )
  //   .pipe(
  //     map((requestconfiguration) =>
  //       RequestConfiguration.map((r) => new RequestConfiguration(this.camelcaseKeys(r)))
  //     )
  //   );
  // }
  updateRequestConfiguration(requestConfiguration: RequestConfiguration) {
    const updatedRequestConfiguration = this.capitalizeKeys({
      ...requestConfiguration,
    });

    return this.httpClient
      .patch<RequestConfiguration>(
        this.createApiEndpointUrl(`apps/Ishtar.OoO/RequestConfiguration`),
        updatedRequestConfiguration,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map(
          (requestconfiguration) =>
            new RequestConfiguration(this.camelcaseKeys(requestconfiguration))
        )
      );
  }

  getTranslations() {
    return this.httpClient.get<any>(
      this.createApiEndpointUrl(
        `ishtarapps/translations?lang=${varlicense$.value?.language}`
      )
    );
  }

  getUsers() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(`users/${this.msal.tenantId}/Ishtar.OoO`)
      )
      .pipe(
        map((user) =>
          user.map(
            (u) =>
              new GroupUser({
                user: new AADUser(this.camelcaseKeys((u as any).User!)),
              })
          )
        )
      );
  }

  getGroups() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(`groups/${this.msal.tenantId}/Ishtar.OoO`)
      )
      .pipe(
        map((group) => group.map((g) => new GroupUser(this.camelcaseKeys(g))))
      );
  }

  getStatus() {
    return this.httpClient
      .get<Status[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarStatus`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((status) => status.map((s) => new Status(this.camelcaseKeys(s))))
      );
  }

  getLanguages() {
    return this.httpClient
      .get<Language[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarLanguage`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((languages) =>
          languages.map((s) => new Language(this.camelcaseKeys(s)))
        )
      );
  }
  getVacationSubtypes() {
    return this.httpClient
      .get<VacationSubtype[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarVacationSubType`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((subtype) =>
          subtype.map((s) => new VacationSubtype(this.camelcaseKeys(s)))
        )
      );
  }

  getMeasureTypes() {
    return this.httpClient
      .get<MeasureType[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarMeasureType`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((measureType) =>
          measureType.map((m) => new MeasureType(this.camelcaseKeys(m)))
        )
      );
  }

  getVacationTypes() {
    return this.httpClient
      .get<VacationType[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarVacationType`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((vacationType) =>
          vacationType.map((v) => new VacationType(this.camelcaseKeys(v)))
        )
      );
  }

  updateVacationTypes(vacationTypes: VacationType[]) {
    const updatedVacationTypes = vacationTypes.map((v) =>
      this.capitalizeKeys({ ...v }, 'VacationSubType', 'MeasureType')
    );
    return this.httpClient
      .patch<VacationType[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarVacationType/records`
        ),
        updatedVacationTypes,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((vacationTypes) =>
          vacationTypes.map((v) => new VacationType(this.camelcaseKeys(v)))
        )
      );
  }

  removeVacationTypes(id: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarVacationType/records`
      ),
      {
        body: id,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  addTeamCalendarItem(teamCalendarItem: TeamCalendar) {
    const triggerTypeId = varnotificationtriggertypes$.value?.find(
      (t) => t.name === 'Created'
    )?.id;
    const addedTeamCalendarItem = this.capitalizeKeys({ ...teamCalendarItem });
    return this.httpClient
      .post<TeamCalendar[]>(
        this.createApiEndpointUrl(
          `ishtar/ooo/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/addVacation/${triggerTypeId}`
        ),
        addedTeamCalendarItem,
        {
          headers: {
            impersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((teamCalendarItems) =>
          teamCalendarItems.map((t) => new TeamCalendar(this.camelcaseKeys(t)))
        )
      );
  }

  cancelRequestTeamCalendarItem(teamCalendarItem: TeamCalendar) {
    const triggerTypeId = varnotificationtriggertypes$.value?.find(
      // (t) => t.name === 'Cancel Request'
      (t) => t.name === 'Updated'
    )?.id;
    const cancelledTeamCalendarItem = this.capitalizeKeys({
      ...teamCalendarItem,
    });
    return this.httpClient
      .patch<TeamCalendar[]>(
        this.createApiEndpointUrl(
          `ishtar/ooo/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/cancelVacation/${triggerTypeId}`
        ),
        cancelledTeamCalendarItem
      )
      .pipe(
        map((teamCalendarItems) =>
          teamCalendarItems.map((t) => new TeamCalendar(this.camelcaseKeys(t)))
        )
      );
  }

  approveTeamCalendarItem(teamCalendarItem: TeamCalendar) {
    const triggerTypeId = varnotificationtriggertypes$.value?.find(
      // (t) => t.name === 'Approved'
      (t) => t.name === 'Updated'
    )?.id;
    const approvedTeamCalendarItem = this.capitalizeKeys({
      ...teamCalendarItem,
    });
    return this.httpClient
      .patch<TeamCalendar[]>(
        this.createApiEndpointUrl(
          `ishtar/ooo/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/approveVacation/${triggerTypeId}`
        ),
        approvedTeamCalendarItem,
        {
          headers: {
            impersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((teamCalendarItems) =>
          teamCalendarItems.map((t) => new TeamCalendar(this.camelcaseKeys(t)))
        )
      );
  }

  rejectTeamCalendarItem(teamCalendarItem: TeamCalendar) {
    const triggerTypeId = varnotificationtriggertypes$.value?.find(
      (t) => t.name === 'Updated'
    )?.id;
    const rejectedTeamCalendarItem = this.capitalizeKeys({
      ...teamCalendarItem,
    });
    return this.httpClient
      .patch<TeamCalendar[]>(
        this.createApiEndpointUrl(
          `ishtar/ooo/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/rejectVacation/${triggerTypeId}`
        ),
        rejectedTeamCalendarItem,
        {
          headers: {
            impersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((teamCalendarItems) =>
          teamCalendarItems.map((t) => new TeamCalendar(this.camelcaseKeys(t)))
        )
      );
  }

  autoApproveTeamCalendarItem(teamCalendarItem: TeamCalendar) {
    const triggerTypeId = varnotificationtriggertypes$.value?.find(
      // (t) => t.name === 'AutoApproved'
      (t) => t.name === 'Created'
    )?.id;
    const addedTeamCalendarItem = this.capitalizeKeys({ ...teamCalendarItem });
    return this.httpClient
      .post<TeamCalendar[]>(
        this.createApiEndpointUrl(
          `ishtar/ooo/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/addVacation/${triggerTypeId}`
        ),
        addedTeamCalendarItem,
        {
          headers: {
            impersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((teamCalendarItems) =>
          teamCalendarItems.map((t) => new TeamCalendar(this.camelcaseKeys(t)))
        )
      );
  }

  addVacationTypes(vacationTypes: VacationType[]) {
    const addedVacationTypes = vacationTypes.map((v) =>
      this.capitalizeKeys({ ...v }, 'VacationSubType', 'MeasureType')
    );
    return this.httpClient
      .post<VacationType[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarVacationType/records`
        ),
        addedVacationTypes,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((vacationTypes) =>
          vacationTypes.map((v) => new VacationType(this.camelcaseKeys(v)))
        )
      );
  }

  getVacationTypeTranslations() {
    return this.httpClient
      .get<VacationTypetranslation[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarVacationTypeTranslation`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((vacationTypeTranslation) =>
          vacationTypeTranslation.map(
            (v) => new VacationTypetranslation(this.camelcaseKeys(v))
          )
        )
      );
  }

  addVacationTypeTranslations(
    vacationTypeTranslations: VacationTypetranslation[]
  ) {
    const addedVacationTypeTranslations = vacationTypeTranslations.map((v) =>
      this.capitalizeKeys({ ...v }, 'Language', 'ParentItem')
    );
    return this.httpClient
      .post<VacationTypetranslation[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarVacationTypeTranslation/records`
        ),
        addedVacationTypeTranslations,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((vacationTypeTranslations) =>
          vacationTypeTranslations.map(
            (v) => new VacationTypetranslation(this.camelcaseKeys(v))
          )
        )
      );
  }

  updateVacationTypeTranslations(
    vacationTypeTranslations: VacationTypetranslation[]
  ) {
    const updatedVacationTypeTranslations = vacationTypeTranslations.map((v) =>
      this.capitalizeKeys({ ...v }, 'Language', 'ParentItem')
    );
    return this.httpClient
      .patch<VacationTypetranslation[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarVacationTypeTranslation/records`
        ),
        updatedVacationTypeTranslations,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((vacationTypeTranslations) =>
          vacationTypeTranslations.map(
            (v) => new VacationTypetranslation(this.camelcaseKeys(v))
          )
        )
      );
  }

  getEmailTemplates() {
    return this.httpClient
      .get<EmailTemplate[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarEmailTemplate`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((emailTemplate) =>
          emailTemplate.map((v) => new EmailTemplate(this.camelcaseKeys(v)))
        )
      );
  }

  updateEmailTemplates(emailTemplates: EmailTemplate[]) {
    const updatedEmailTemplates = emailTemplates.map((v) =>
      this.capitalizeKeys({ ...v }, 'Language')
    );
    return this.httpClient
      .patch<EmailTemplate[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarEmailTemplate/records`
        ),
        updatedEmailTemplates,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((emailTemplates) =>
          emailTemplates.map((v) => new EmailTemplate(this.camelcaseKeys(v)))
        )
      );
  }

  getBalances() {
    return this.httpClient
      .get<Balance[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarSaldo`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((balance) => balance.map((b) => new Balance(this.camelcaseKeys(b))))
      );
  }

  updateBalances(balances: Balance[]) {
    const updatedBalances = balances.map((b) =>
      this.capitalizeKeys({ ...b }, 'VacationType')
    );
    return this.httpClient
      .patch<Balance[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarSaldo/records`
        ),
        updatedBalances,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((balances) =>
          balances.map((b) => new Balance(this.camelcaseKeys(b)))
        )
      );
  }

  // getBalances() {
  //   return this.httpClient
  //     .get<Balance[]>(
  //       this.createApiEndpointUrl(
  //         `dataverse/${this.msal.tenantId}/${
  //           varlicense$.value!.dataverseEnvironmentUrl
  //         }/IshtarSaldo`
  //       ),
  //       {
  //         headers: {
  //           ImpersonationUserAADId: varlicense$.value!.microsoftId,
  //         },
  //       }
  //     )
  //     .pipe(
  //       map((balance) =>
  //         balance.map((b) => new Balance(this.camelcaseKeys(b)))
  //       )
  //     );
  // }

  // updateBalances(balances: Balance[]) {
  //   const updatedBalances = balances.map((b) =>
  //     this.capitalizeKeys({...b}, 'VacationType')
  //   );
  //   return this.httpClient
  //     .patch<Balance[]>(
  //       this.createApiEndpointUrl(
  //         `dataverse/${this.msal.tenantId}/${
  //           varlicense$.value!.dataverseEnvironmentUrl
  //         }/IshtarSaldo/records`
  //       ),
  //       updatedBalances,
  //       {
  //         headers: {
  //           ImpersonationUserAADId: varlicense$.value!.microsoftId,
  //         },
  //       }
  //     )
  //     .pipe(
  //       map((balances) =>
  //         balances.map((b) => new Balance(this.camelcaseKeys(b)))
  //       )
  //     );
  // }

  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),

        {}
      );
    else return obj;
  }
  capitalizeKeys(obj: any, ...ignoredProperties: string[]): any {
    const ignoredPropertiesLower = ignoredProperties.map((p) =>
      p.toLowerCase()
    );

    if (Array.isArray(obj))
      return [...obj.map((o) => this.capitalizeKeys(o, ...ignoredProperties))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toUpperCase() + e[0].slice(1)]:
            ignoredPropertiesLower.includes(e[0].toLowerCase())
              ? e[1]
              : this.capitalizeKeys(e[1], ...ignoredProperties),
        }),

        {}
      );
    else return obj;
  }

  getTeamCalendars() {
    return this.httpClient
      .get<TeamCalendar[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarTeamCalendar`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((TeamCalendarArray) =>
          TeamCalendarArray.map((t) => new TeamCalendar(this.camelcaseKeys(t)))
        )
      );
  }

  addTeamCalendars(TeamCalendars: TeamCalendar[]) {
    const addedTeamCalendars = TeamCalendars.map((t) => this.capitalizeKeys(t));
    return this.httpClient
      .post<TeamCalendar[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarTeamCalendar/records`
        ),
        addedTeamCalendars,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((TeamCalendarArray) =>
          TeamCalendarArray.map((t) => new TeamCalendar(this.camelcaseKeys(t)))
        )
      );
  }

  removeTeamCalendars(id: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarTeamCalendar/records`
      ),
      {
        body: id,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  updateTeamCalendars(teamCalendar: TeamCalendar[]) {
    const updatedTeamCalendars = teamCalendar.map((t) =>
      this.capitalizeKeys(t)
    );
    return this.httpClient
      .patch<VacationType[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarTeamCalendar/records`
        ),
        updatedTeamCalendars,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((TeamCalendarArray) =>
          TeamCalendarArray.map((t) => new TeamCalendar(this.camelcaseKeys(t)))
        )
      );
  }

  createApplicationNotification(
    applicationNotification: CreateNotificationrequest
  ) {
    return this.httpClient.post<ApplicationNotification>(
      this.createApiEndpointUrl(
        `ishtarapps/application-notification/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/createNotification`
      ),
      applicationNotification
    );
  }

  getNotificationChannelTypes() {
    return this.httpClient
      .get<Lookup[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarChannelType`
        )
      )
      .pipe(
        map((channelTypes) =>
          channelTypes.map((c) => new Lookup(this.camelcaseKeys(c)))
        )
      );
  }

  getNotificationTriggerTypes() {
    return this.httpClient
      .get<Lookup[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarTriggerType`
        )
      )
      .pipe(
        map((triggerTypes) =>
          triggerTypes.map((t) => new Lookup(this.camelcaseKeys(t)))
        )
      );
  }

  getWorkRegimeDays() {
    return this.httpClient
      .get<WorkRegimeDay[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarWorkRegimeDay?expand=WorkRegime&select=*, WorkRegime`
        )
      )
      .pipe(
        map((workRegimeDays) =>
          workRegimeDays.map((w) => new WorkRegimeDay(this.camelcaseKeys(w)))
        )
      );
  }

  addWorkRegimeDays(workRegimeDays: WorkRegimeDay[]) {
    const addedWorkRegimeDays = workRegimeDays.map((w) =>
      this.capitalizeKeys({ ...w }, 'workRegime', 'workRegimeType', 'day')
    );
    return this.httpClient
      .post<WorkRegimeDay[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarWorkRegimeDay/records`
        ),
        addedWorkRegimeDays
      )
      .pipe(
        map((workRegimeDays) =>
          workRegimeDays.map((w) => new WorkRegimeDay(this.camelcaseKeys(w)))
        )
      );
  }

  updateWorkRegimeDays(workRegimeDays: WorkRegimeDay[]) {
    const updatedWorkRegimeDays = workRegimeDays.map((w) =>
      this.capitalizeKeys({ ...w }, 'workRegime', 'workRegimeType', 'day')
    );
    return this.httpClient
      .patch<WorkRegimeDay[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarWorkRegimeDay/records`
        ),
        updatedWorkRegimeDays
      )
      .pipe(
        map((workRegimeDays) =>
          workRegimeDays.map((w) => new WorkRegimeDay(this.camelcaseKeys(w)))
        )
      );
  }
}
