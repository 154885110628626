import { createAction, props } from '@ngrx/store';
import { EmailTemplate } from '../../models/email-template';
import { GroupUser } from '../../models/user/groupuser';

export const getEmailTemplates = createAction(
  '[EmailTemplate] Get emailTemplates',
  props<{
    callback?: () => void;
  }>()
);

export const getEmailTemplatesResolved = createAction(
  '[EmailTemplate] Get emailTemplates resolved',
  props<{
    emailTemplates: EmailTemplate[];
  }>()
);

export const updateEmailTemplates = createAction(
  '[EmailTemplate] Update emailTemplates',
  props<{ emailTemplates: EmailTemplate[]; callback?: () => void }>()
);

export const updateEmailTemplatesResolved = createAction(
  '[EmailTemplate] Update emailTemplates resolved',
  props<{ updatedEmailTemplates: EmailTemplate[] }>()
);

export const getUsers = createAction(
  '[GroupUser] Get groupUsers',
  props<{
    callback?: () => void;
  }>()
);
export const getUsersResolved = createAction(
  '[GroupUser] Get groupUsers resolved',
  props<{
    users: GroupUser[];
  }>()
);
