import { Action, createReducer, on } from '@ngrx/store';
import { VacationSubtype } from '../../models/vacation-subtype';
import { AppState } from '../../../app.reducer';
import {
  getVacationSubTypesResolved,
} from './vacation-subtype.actions';

export const featureSlice = 'vacationSubType';

export interface State {
  vacationSubTypes: VacationSubtype[];
  vacationSubType?: VacationSubtype;
  ishtarVacationSubtypeIds?: string[];
}
const defaultState: State = {
  vacationSubTypes: [],
  vacationSubType: undefined,
  ishtarVacationSubtypeIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return vacationSubTypeReducer(state, action);
}

export const initialState: State = defaultState;

export const vacationSubTypeReducer = createReducer(
  initialState,
  on(getVacationSubTypesResolved, (state, { vacationSubTypes }) => ({
    ...state,
    vacationSubTypes: [...vacationSubTypes],
  })),
);

export const vacationSubTypeState = (state: AppState) =>
  state.coreFeature.vacationSubType;
