import { Action, createReducer, on } from '@ngrx/store';
import { EmailTemplate } from '../../models/email-template';
import { AppState } from '../../../app.reducer';
import {
  getEmailTemplatesResolved,
  getUsersResolved,
  updateEmailTemplatesResolved,
} from './email-template.actions';
import { GroupUser } from '../../models/user/groupuser';

export const featureSlice = 'emailTemplate';

export interface State {
  emailTemplates: EmailTemplate[];
  emailTemplate?: EmailTemplate;
  ishtarEmailTemplatesIds?: string[];
  users: GroupUser[];
  user?: GroupUser;
}
const defaultState: State = {
  emailTemplates: [],
  emailTemplate: undefined,
  ishtarEmailTemplatesIds: [],
  users: [],
  user: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return emailTemplateReducer(state, action);
}

export const initialState: State = defaultState;

export const emailTemplateReducer = createReducer(
  initialState,
  on(getEmailTemplatesResolved, (state, { emailTemplates }) => ({
    ...state,
    emailTemplates: [...emailTemplates],
  })),
  on(updateEmailTemplatesResolved, (state, { updatedEmailTemplates }) => ({
    ...state,
    emailTemplates: state.emailTemplates.map(
      (s) =>
        updatedEmailTemplates.find(
          (u) => s.ishtarEmailTemplateId === u.ishtarEmailTemplateId
        ) ?? s
    ),
  })),
  on(getUsersResolved, (state, { users }) => ({
    ...state,
    users: [...users],
  }))
);

export const emailTemplateState = (state: AppState) =>
  state.coreFeature.emailTemplate;
