import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Subject } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { balanceState } from './balance.reducer';
import {
    getBalances,
    getBalancesResolved,
    updateBalances,
    updateBalancesResolved,
} from './balance.actions';
import { Balance } from '../../models/balance';

@Injectable({
  providedIn: 'root',
})
export class BalanceFacade {
    balances$ = this._store.pipe(
        select(balanceState),
        map((state) => state.balances)
    );
    
    constructor(private _store: Store<AppState>) {}
    
  getBalances$(callback?: (balances: Balance[]) => void) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getBalances({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateBalances(balances: Balance[]) {
    this._store.dispatch(updateBalances({ balances }));
  }
}
