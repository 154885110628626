import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromCoreFeature from './core/store/core.feature';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import {
  ActionBarComponent,
  DashboardComponent,
} from 'processdelight-angular-components';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatNativeDateModule,
    StoreModule.forFeature(
      fromCoreFeature.featureKey,
      fromCoreFeature.reducers
    ),
    EffectsModule.forFeature(fromCoreFeature.effects),
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    ActionBarComponent,
    MatTableModule,
    DashboardComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    ActionBarComponent,
    MatTableModule,
    DashboardComponent,
  ],
})
export class CoreModule {}
