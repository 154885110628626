import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarOoOService } from '../../services/ooo.service';
import {
  addTeamCalendars,
  addTeamCalendarsResolved,
  addWorkRegimeDays,
  addWorkRegimeDaysResolved,
  getTeamCalendars,
  getTeamCalendarsResolved,
  getWorkRegimeDays,
  getWorkRegimeDaysResolved,
  removeTeamCalendars,
  removeTeamCalendarsResolved,
  updateTeamCalendars,
  updateTeamCalendarsResolved,
  updateWorkRegimeDays,
  updateWorkRegimeDaysResolved,
} from './team-calendar.actions';

@Injectable({ providedIn: 'root' })
export class TeamCalendarEffects {
  constructor(
    private actions$: Actions,
    private ishtarOoOService: IshtarOoOService
  ) {}

  getTeamCalendars = createEffect(() =>
    this.actions$.pipe(
      ofType(getTeamCalendars),
      switchMap(({ callback }) =>
        this.ishtarOoOService.getTeamCalendars().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((teamCalendars) =>
            of(
              getTeamCalendarsResolved({
                teamCalendars,
              })
            )
          ),

          catchError((e) => [])
        )
      )
    )
  );

  addTeamCalendars = createEffect(() =>
    this.actions$.pipe(
      ofType(addTeamCalendars),
      switchMap(({ teamCalendars }) =>
        this.ishtarOoOService.addTeamCalendars(teamCalendars).pipe(
          switchMap((addedTeamCalendars) =>
            of(addTeamCalendarsResolved({ addedTeamCalendars }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  updateTeamCalendars = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTeamCalendars),
      switchMap(({ teamCalendars }) =>
        this.ishtarOoOService.updateTeamCalendars(teamCalendars).pipe(
          switchMap((updatedTeamCalendars) =>
            of(updateTeamCalendarsResolved({ updatedTeamCalendars }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  removeTeamCalendars = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTeamCalendars),
      switchMap(({ ids, callback }) =>
        this.ishtarOoOService.removeTeamCalendars(ids).pipe(
          switchMap((ishtarTeamCalendarIds) =>
            of(removeTeamCalendarsResolved({ ishtarTeamCalendarIds }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getWorkRegimeDays = createEffect(() =>
    this.actions$.pipe(
      ofType(getWorkRegimeDays),
      switchMap(({ callback }) =>
        this.ishtarOoOService.getWorkRegimeDays().pipe(
          tap((result) => (callback ? callback(result) : undefined)),
          switchMap((result) => of(getWorkRegimeDaysResolved({ result }))),
          catchError((e) => [])
        )
      )
    )
  );

  addWorkRegimeDays = createEffect(() =>
    this.actions$.pipe(
      ofType(addWorkRegimeDays),
      switchMap(({ workRegimeDays }) =>
        this.ishtarOoOService.addWorkRegimeDays(workRegimeDays).pipe(
          switchMap((addedWorkRegimeDays) =>
            of(addWorkRegimeDaysResolved({ addedWorkRegimeDays }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  updateWorkRegimeDays = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWorkRegimeDays),
      switchMap(({ workRegimeDays }) =>
        this.ishtarOoOService.updateWorkRegimeDays(workRegimeDays).pipe(
          switchMap((updatedWorkRegimeDays) =>
            of(updateWorkRegimeDaysResolved({ updatedWorkRegimeDays }))
          ),
          catchError((e) => [])
        )
      )
    )
  );
}
