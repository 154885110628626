import { createAction, props } from '@ngrx/store';
import { Balance } from '../../models/balance';

export const getBalances = createAction(
  '[Balance] Get balances',
  props<{
    callback?: () => void;
  }>()
);

export const getBalancesResolved = createAction(
  '[Balance] Get balances resolved',
  props<{
    balances: Balance[];
  }>()
);

export const updateBalances = createAction(
  '[Balance] Update balances',
  props<{ balances: Balance[]; callback?: () => void }>()
);

export const updateBalancesResolved = createAction(
  '[Balances] Update balances resolved',
  props<{ updatedBalances: Balance[] }>()
);