import { createAction, props } from '@ngrx/store';
import { MeasureType } from '../../models/measure-type';

export const getMeasureTypes = createAction(
  '[MeasureType] Get measureTypes',
  props<{
    callback?: () => void;
  }>()
);

export const getMeasureTypesResolved = createAction(
  '[MeasureType] Get measureTypes resolved',
  props<{
    measureTypes: MeasureType[];
  }>()
);
