import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarOoOService } from '../../services/ooo.service';
import {
    getBalances,
    getBalancesResolved,
    updateBalances,
    updateBalancesResolved,
} from './balance.actions';
import { call } from '@microsoft/teams-js';

@Injectable({ providedIn: 'root' })
export class BalanceEffects {
  constructor(
    private actions$: Actions,
    private ishtarOoOService: IshtarOoOService
  ) {}

  getBalances = createEffect(() =>
    this.actions$.pipe(
      ofType(getBalances),
      switchMap(({ callback }) =>
        this.ishtarOoOService.getBalances().pipe(
          switchMap((balances) =>
            of(
                getBalancesResolved({
                balances,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  updateBalances = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBalances),
      switchMap(({ balances }) =>
        this.ishtarOoOService.updateBalances(balances).pipe(
          switchMap((updatedBalances) =>
            of(updateBalancesResolved({ updatedBalances }))
          ),
          catchError((e) => [])
        )
      )
    )
  );
}
