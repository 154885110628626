import { Action, createReducer, on } from '@ngrx/store';
import { RequestConfiguration } from '../../models/requestConfiguration';
import { AppState } from '../../../app.reducer';
import {
    getRequestConfigurationResolved,
    updateRequestConfigurationResolved,
  } from './requestConfiguration.actions';

export const featureSlice = 'requestConfiguration';

export interface State {
  // requestConfigurations: RequestConfiguration[];
  requestConfiguration?: RequestConfiguration;
  ishtarRequestConfigurationIds?: string[];
}
const defaultState: State = {
  // requestConfigurations: [],
  requestConfiguration: undefined,
  ishtarRequestConfigurationIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return requestConfigurationReducer(state, action);
}

export const initialState: State = defaultState;

export const requestConfigurationReducer = createReducer(
  initialState,
  on(getRequestConfigurationResolved, (state, { requestConfiguration }) => ({
    ...state,
    requestConfiguration: requestConfiguration,
  })),
  on(updateRequestConfigurationResolved, (state, { updatedRequestConfiguration }) => ({
    ...state,
    updatedRequestedConfiguration: updatedRequestConfiguration
  })),
);

export const requestConfigurationState = (state: AppState) =>
  state.coreFeature.requestConfiguration;
