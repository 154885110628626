import { createAction, props } from '@ngrx/store';
import { RequestConfiguration } from '../../models/requestConfiguration';

export const getRequestConfiguration = createAction(
  '[RequestConfiguration] Get requestConfiguration',
  props<{
    callback?: () => void;
  }>()
);

export const getRequestConfigurationResolved = createAction(
  '[RequestConfiguration] Get requestConfiguration resolved',
  props<{
    requestConfiguration: RequestConfiguration;
  }>()
);

export const updateRequestConfiguration = createAction(
  '[RequestConfiguration] Update requestConfiguration',
  props<{ requestConfiguration: RequestConfiguration; callback?: () => void }>()
);

export const updateRequestConfigurationResolved = createAction(
  '[RequestConfiguration] Update RequestConfiguration resolved',
  props<{ updatedRequestConfiguration: RequestConfiguration }>()
);