import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarOoOService } from '../../services/ooo.service';
import {
  getMeasureTypes,
  getMeasureTypesResolved,
} from './measure-type.actions';

@Injectable({ providedIn: 'root' })
export class MeasuretypeEffects {
    constructor(
        private actions$: Actions,
        private ishtarOoOService: IshtarOoOService
    ) {}

    getMeasuretypes = createEffect(() =>
        this.actions$.pipe(
            ofType(getMeasureTypes),
            switchMap(({ callback }) =>
            this.ishtarOoOService.getMeasureTypes().pipe(
                switchMap((measuretypes) =>
                of(
                    getMeasureTypesResolved({
                        measureTypes: measuretypes,
                    })
                )
                ),
                tap(() => (callback ? callback() : undefined)),
                catchError((e) => [])
            ))
        )
    );
}