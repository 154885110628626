export class VacationType {
  ishtarVacationTypeId?: string;
  title!: string;
  defaultSaldo?: number;
  hasSaldo!: boolean;
  groupEdit!: boolean;
  measureType!: { id?: string; name?: string };
  vacationSubType!: { id?: string; name?: string };
  name!: string;

  constructor(obj: Partial<VacationType>) {
    Object.assign(this, obj);
  }
}