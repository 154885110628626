import { createAction, props } from '@ngrx/store';
import { TeamCalendar } from '../../models/teamCalendar';
import { WorkRegimeDay } from '../../models/workRegimeDay';

export const getTeamCalendars = createAction(
  '[TeamCalendar] Get teamCalendars',
  props<{
    callback?: (teamcalendars: TeamCalendar[]) => void;
  }>()
);

export const getTeamCalendarsResolved = createAction(
  '[TeamCalendar] Get teamCalendars resolved',
  props<{
    teamCalendars: TeamCalendar[];
  }>()
);

export const removeTeamCalendars = createAction(
  '[TeamCalendar] Remove teamCalendar',
  props<{ ids: string[]; callback?: () => void }>()
);

export const removeTeamCalendarsResolved = createAction(
  '[TeamCalendar] Remove teamCalendar resolved',
  props<{ ishtarTeamCalendarIds: string[] }>()
);

export const updateTeamCalendars = createAction(
  '[TeamCalendars] Update teamCalendars',
  props<{ teamCalendars: TeamCalendar[]; callback?: () => void }>()
);

export const updateTeamCalendarsResolved = createAction(
  '[TeamCalendars] Update teamCalendars resolved',
  props<{ updatedTeamCalendars: TeamCalendar[] }>()
);

export const addTeamCalendars = createAction(
  '[TeamCalendars] Add teamCalendars',
  props<{ teamCalendars: TeamCalendar[] }>()
);

export const addTeamCalendarsResolved = createAction(
  '[TeamCalendars] Add teamCalendars resolved',
  props<{ addedTeamCalendars: TeamCalendar[] }>()
);

export const getWorkRegimeDays = createAction(
  '[IshtarWorkRegimeDay] Get work regime days',
  props<{ callback?: (workRegimeDays: WorkRegimeDay[]) => void }>()
);

export const getWorkRegimeDaysResolved = createAction(
  '[IshtarWorkRegimeDay] Get work regime days resolved',
  props<{ result: WorkRegimeDay[] }>()
);

export const updateWorkRegimeDays = createAction(
  '[IshtarWorkRegimeDay] Update work regime days',
  props<{ workRegimeDays: WorkRegimeDay[] }>()
);

export const updateWorkRegimeDaysResolved = createAction(
  '[IshtarWorkRegimeDay] Update work regime days resolved',
  props<{ updatedWorkRegimeDays: WorkRegimeDay[] }>()
);

export const addWorkRegimeDays = createAction(
  '[IshtarWorkRegimeDay] Add work regime days',
  props<{ workRegimeDays: WorkRegimeDay[] }>()
);

export const addWorkRegimeDaysResolved = createAction(
  '[IshtarWorkRegimeDay] Add work regime days resolved',
  props<{ addedWorkRegimeDays: WorkRegimeDay[] }>()
);
