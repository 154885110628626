<div
  *ngIf="{
    orgLogo: orgLogo$ | async,
    userName: userName$ | async,
    userPhoto: userPhoto$ | async,
    navColor: navColor$ | async,
    navContrast: navContrast$ | async,
  } as data"
  class="d-flex flex-column h-100"
>
  <processdelight-loader
    [appLogo]="appLogo"
    [appName]="title"
    [navColor]="data.navColor || '#124464'"
  ></processdelight-loader>
  <processdelight-action-bar
    [navActions]="actions"
    [navColor]="data.navColor || '#124464'"
    [navContrast]="data.navContrast || '#fff'"
    [iconActions]="iconActions"
  ></processdelight-action-bar>

  <router-outlet></router-outlet>
</div>
