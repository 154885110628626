export class EmailTemplate {
  label!: string;
  ishtarEmailTemplateId!: string;
  language!: { id?: string; name?: string };
  template?: string;
  subject!: string;
  status!: string;

  constructor(obj: Partial<EmailTemplate>) {
    Object.assign(this, obj);
  }
}
