import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarOoOService } from '../../services/ooo.service';
import {
    addVacationTypeTranslations,
    addVacationTypeTranslationsResolved,
  getVacationTypeTranslations,
  getVacationTypeTranslationsResolved,
  updateVacationTypeTranslations,
  updateVacationTypeTranslationsResolved,
} from './vacation-typeTranslation.actions';

@Injectable({ providedIn: 'root' })
export class VacationTypeTranslationEffects {
    constructor(
        private actions$: Actions,
        private ishtarOoOService: IshtarOoOService
    ) {}

    getVacationTypeTranslations = createEffect(() =>
        this.actions$.pipe(
            ofType(getVacationTypeTranslations),
            switchMap(({ callback }) =>
            this.ishtarOoOService.getVacationTypeTranslations().pipe(
                switchMap((vacationtypetranslations) =>
                of(
                    getVacationTypeTranslationsResolved({
                        vacationTypeTranslations: vacationtypetranslations,
                    })
                )
                ),
                tap(() => (callback ? callback() : undefined)),
                catchError((e) => [])
            ))
        )
    );

  addVacationTypeTranslations = createEffect(() =>
    this.actions$.pipe(
      ofType(addVacationTypeTranslations),
      switchMap(({ vacationTypeTranslations }) =>
        this.ishtarOoOService.addVacationTypeTranslations(vacationTypeTranslations).pipe(
          switchMap((addedVacationTypeTranslations) =>
            of(addVacationTypeTranslationsResolved({ addedVacationTypeTranslations }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  updateVacationTypeTranslations = createEffect(() =>
    this.actions$.pipe(
        ofType(updateVacationTypeTranslations),
        switchMap(({ vacationTypeTranslations }) =>
        this.ishtarOoOService.updateVacationTypeTranslations(vacationTypeTranslations).pipe(
            switchMap((updatedVacationTypeTranslations) =>
            of(updateVacationTypeTranslationsResolved({ updatedVacationTypeTranslations }))
            ),
            catchError((e) => [])
        )
        )
    )
  );
}