import { GroupUser } from 'processdelight-angular-components';

export class WorkRegimeDay {
  ishtarWorkRegimeDayId?: string;
  workRegimeType!: { id: string; name?: string };
  workRegime?: {
    id?: string;
    user: GroupUser;
    capacity: number;
    name?: string;
  };
  week!: number;
  day!: { id: string; name?: string };

  constructor(obj: Partial<WorkRegimeDay>) {
    Object.assign(this, obj);
  }
}
