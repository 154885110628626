import { Action, createReducer, on } from '@ngrx/store';
import { VacationTypetranslation } from '../../models/vacation-typeTranslation';
import { AppState } from '../../../app.reducer';
import {
  addVacationTypeTranslationsResolved,
  getVacationTypeTranslationsResolved,
  updateVacationTypeTranslationsResolved,
} from './vacation-typeTranslation.actions';

export const featureSlice = 'vacationTypeTranslation';

export interface State {
    vacationTypeTranslations: VacationTypetranslation[];
    vacationTypeTranslation?: VacationTypetranslation;
    ishtarvacationTypeTranslationIds?: string[];
}
const defaultState: State = {
    vacationTypeTranslations: [],
    vacationTypeTranslation: undefined,
    ishtarvacationTypeTranslationIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return vacationTypeTranslationReducer(state, action);
}

export const initialState: State = defaultState;

export const vacationTypeTranslationReducer = createReducer(
  initialState,
  on(getVacationTypeTranslationsResolved, (state, { vacationTypeTranslations }) => ({
    ...state,
    vacationTypeTranslations: [...vacationTypeTranslations],
  })),
  on(addVacationTypeTranslationsResolved, (state, { addedVacationTypeTranslations }) => ({
    ...state,
    vacationTypes: addedVacationTypeTranslations.concat([...state.vacationTypeTranslations]),
  })),
  on(updateVacationTypeTranslationsResolved, (state, { updatedVacationTypeTranslations }) => ({
    ...state,
    vacationTypeTranslations: state.vacationTypeTranslations.map(
      (s) =>
        updatedVacationTypeTranslations.find(
          (u) => s.ishtarVacationTypeTranslationId === u.ishtarVacationTypeTranslationId
        ) ?? s
    ),
  })),
);

export const vacationTypeTranslationState = (state: AppState) =>
  state.coreFeature.vacationTypeTranslation;
