import { createAction, props } from '@ngrx/store';
import { VacationTypetranslation } from '../../models/vacation-typeTranslation';

export const getVacationTypeTranslations = createAction(
  '[VacationTypeTranslation] Get vacationTypeTranslations',
  props<{
    callback?: () => void;
  }>()
);

export const getVacationTypeTranslationsResolved = createAction(
  '[VacationTypeTranslation] Get vacationTypeTranslations resolved',
  props<{
    vacationTypeTranslations: VacationTypetranslation[];
  }>()
);

export const addVacationTypeTranslations = createAction(
  '[VacationTypeTranslations] Add vacationTypeTranslations',
  props<{ vacationTypeTranslations: VacationTypetranslation[] }>()
);

export const addVacationTypeTranslationsResolved = createAction(
  '[VacationTypeTranslations] Add vacationTypeTranslations resolved',
  props<{ addedVacationTypeTranslations: VacationTypetranslation[] }>()
);

export const updateVacationTypeTranslations = createAction(
  '[VacationTypesTranslations] Update vacationTypeTranslations',
  props<{ vacationTypeTranslations: VacationTypetranslation[]; callback?: () => void }>()
);

export const updateVacationTypeTranslationsResolved = createAction(
  '[VacationTypeTranslations] Update vacationTypeTranslations resolved',
  props<{ updatedVacationTypeTranslations: VacationTypetranslation[] }>()
);
