import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarOoOService } from '../../services/ooo.service';
import { getRequestConfiguration, getRequestConfigurationResolved, updateRequestConfiguration, updateRequestConfigurationResolved } from './requestConfiguration.actions';

@Injectable({ providedIn: 'root' })
export class RequestConfigurationEffects {
    constructor(
        private actions$: Actions,
        private ishtarOoOService: IshtarOoOService
    ) {}

    getRequestConfiguration = createEffect(() =>
        this.actions$.pipe(
            ofType(getRequestConfiguration),
            switchMap(({ callback }) =>
            this.ishtarOoOService.getRequestConfiguration().pipe(
                switchMap((requestconfiguration) =>
                of(
                    getRequestConfigurationResolved({
                        requestConfiguration: requestconfiguration,
                    })
                )
                ),
                tap(() => (callback ? callback() : undefined)),
                catchError((e) => [])
            ))
        )
    );

    updateRequestConfiguration = createEffect(() =>
    this.actions$.pipe(
        ofType(updateRequestConfiguration),
        switchMap(({ requestConfiguration }) =>
        this.ishtarOoOService.updateRequestConfiguration(requestConfiguration).pipe(
            switchMap((updatedRequestConfiguration) =>
            of(updateRequestConfigurationResolved({ updatedRequestConfiguration }))
            ),
            catchError((e) => [])
        )
        )
    )
  );
}