import { VacationType } from "./vacation-type";

export class VacationTypetranslation {
    ishtarVacationTypeTranslationId!: string;
    translation!: string;
    language!: {id?: string, name?: string};
    parentItem!: {name?: string, id?: string};
    name?: string;
  
    constructor(obj: Partial<VacationTypetranslation>) {
      Object.assign(this, obj);
    }
  }
  