import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Subject } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { vacationTypeState } from './vacation-type.reducer';
import {
  getVacationTypes,
  addVacationTypes,
  removeVacationTypes,
  updateVacationTypes,
} from './vacation-type.actions';
import { VacationType } from '../../models/vacation-type';
import { VacationTypetranslation } from '../../models/vacation-typeTranslation';

@Injectable({
  providedIn: 'root',
})
export class VacationTypeFacade {
  vacationTypes$ = this._store.pipe(
    select(vacationTypeState),
    map((state) => state.vacationTypes)
    );
    
    constructor(private _store: Store<AppState>) {}
    
  getVacationTypes$(callback?: (vacationTypes: VacationType[]) => void) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getVacationTypes({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  addVacationTypes(vacationTypes: VacationType[]) {
    const subject = new Subject<VacationType[]>();
    this._store.dispatch(addVacationTypes({ vacationTypes, callback: (x) => {
      subject.next(x);
      subject.complete
    } }));
    return subject.asObservable()
  }

  removeVacationTypes(ids: string[]) {
    const subject = new Subject<void>();
    this._store.dispatch(
      removeVacationTypes({
        ids,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateVacationTypes(vacationTypes: VacationType[]) {
    this._store.dispatch(updateVacationTypes({ vacationTypes }));
  }
}
