import { Action, createReducer, on } from '@ngrx/store';
import { VacationType } from '../../models/vacation-type';
import { AppState } from '../../../app.reducer';
import {
  getVacationTypesResolved,
  removeVacationTypesResolved,
  updateVacationTypesResolved,
  addVacationTypesResolved,
} from './vacation-type.actions';

export const featureSlice = 'vacationType';

export interface State {
  vacationSubTypes: any;
  vacationTypes: VacationType[];
  vacationType?: VacationType;
  ishtarVacationTypeIds?: string[];
}
const defaultState: State = {
  vacationTypes: [],
  vacationType: undefined,
  ishtarVacationTypeIds: [],
  vacationSubTypes: undefined
};

export function Reducer(state: State | undefined, action: Action) {
  return vacationTypeReducer(state, action);
}

export const initialState: State = defaultState;

export const vacationTypeReducer = createReducer(
  initialState,
  on(getVacationTypesResolved, (state, { vacationTypes }) => ({
    ...state,
    vacationTypes: [...vacationTypes],
  })),
  on(removeVacationTypesResolved, (state, { ishtarVacationTypeIds }) => ({
    ...state,
    vacationTypes: state.vacationTypes.filter(
      (s) => !ishtarVacationTypeIds.includes(s.ishtarVacationTypeId!)
    ),
  })),
  on(updateVacationTypesResolved, (state, { updatedVacationTypes }) => ({
    ...state,
    vacationTypes: state.vacationTypes.map(
      (s) =>
        updatedVacationTypes.find(
          (u) => s.ishtarVacationTypeId === u.ishtarVacationTypeId
        ) ?? s
    ),
  })),
  on(addVacationTypesResolved, (state, { addedVacationTypes }) => ({
    ...state,
    vacationTypes: addedVacationTypes.concat([...state.vacationTypes]),
  }))
);

export const vacationTypeState = (state: AppState) =>
  state.coreFeature.vacationType;
