import { GroupUser } from 'processdelight-angular-components';
import { Balance } from 'src/app/core/models/balance';

export interface CustomBalance {
  user: GroupUser;
  balances: Balance[];
  collapsed?: boolean;
}

export class BalanceHolder {
  user!: GroupUser;
  balances!: Balance[];
  collapsed!: boolean;
  constructor(obj: Partial<BalanceHolder>) {
    Object.assign(this, obj);
  }
}
