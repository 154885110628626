import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable, Subject } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { teamCalendarState } from './team-calendar.reducer';
import {
  getTeamCalendars,
  addTeamCalendars,
  removeTeamCalendars,
  updateTeamCalendars,
  addWorkRegimeDays,
  updateWorkRegimeDays,
  getWorkRegimeDays,
} from './team-calendar.actions';
import { TeamCalendar } from '../../models/teamCalendar';
import { WorkRegimeDay } from '../../models/workRegimeDay';

@Injectable({
  providedIn: 'root',
})
export class TeamCalendarFacade {
  teamCalendars$ = this._store.pipe(
    select(teamCalendarState),
    map((state) => state.teamCalendars)
  );
  workRegimeDays$ = this._store.pipe(
    select(teamCalendarState),
    map((state) => state.workRegimeDays)
  );

  constructor(private _store: Store<AppState>) {}

  getTeamCalendars$() {
    const subject = new Subject<TeamCalendar[]>();
    this._store.dispatch(
      getTeamCalendars({
        callback: (x) => {
          subject.next(x);
          subject.complete();
        },
      })
    );

    return subject.asObservable();
  }

  addTeamCalendars(teamCalendars: TeamCalendar[]) {
    this._store.dispatch(addTeamCalendars({ teamCalendars }));
  }

  removeTeamCalendars(ids: string[]) {
    const subject = new Subject<void>();
    this._store.dispatch(
      removeTeamCalendars({
        ids,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateTeamCalendars(teamCalendars: TeamCalendar[]) {
    this._store.dispatch(updateTeamCalendars({ teamCalendars }));
  }

  getWorkRegimeDays$() {
    const subject = new Subject<WorkRegimeDay[]>();
    this._store.dispatch(
      getWorkRegimeDays({
        callback: (workRegimeDays) => {
          subject.next(workRegimeDays);
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  addWorkRegimeDays(workRegimeDays: WorkRegimeDay[]) {
    this._store.dispatch(addWorkRegimeDays({ workRegimeDays }));
  }

  updateWorkRegimeDays(workRegimeDays: WorkRegimeDay[]) {
    this._store.dispatch(updateWorkRegimeDays({ workRegimeDays }));
  }
}
