export class RequestConfiguration {
  ishtarRequestConfigurationId!: string;
  onRequest?: boolean;
  onRequestTemplate?: Map<string, string>;

  onApprove?: boolean;
  onApproveTemplate?: Map<string, string>;

  onReject?: boolean;
  onRejectTemplate?: Map<string, string>;

  onCancelApprove?: boolean;
  onCancelApproveTemplate?: Map<string, string>;

  onCancelReject?: boolean;
  onCancelRejectTemplate?: Map<string, string>;

  onAdminRequest?: boolean;
  onAdminRequestTemplate?: Map<string, string>;

  onRequestCancel?: boolean;
  onRequestCancelTemplate?: Map<string, string>;

  constructor(obj: Partial<RequestConfiguration>) {
    Object.assign(this, obj);
  }
}
