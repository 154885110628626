import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  filter,
  first,
  forkJoin,
  switchMap,
  tap,
} from 'rxjs';
import { GroupUser } from '../models/user/groupuser';
import { UserLicenseInfo } from '../models/user/userlicenseinfo';
import { Status } from '../models/status';

import { AppInfo } from '../models/user/appInfo';
import { IshtarOoOService } from './ooo.service';
import { VacationSubtype } from '../models/vacation-subtype';
import { MeasureType } from '../models/measure-type';
import { TeamCalendarFacade } from '../store/team-calendar/team-calendar.facade';
import { Language } from '../models/language';
import {
  LoaderService,
  Lookup,
  TranslationService,
} from 'processdelight-angular-components';
import { getWorkRegimeDays } from '../store/team-calendar/team-calendar.actions';

export const varlicense$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);

export const varappinfo$ = new BehaviorSubject<AppInfo | undefined>(undefined);

export const varusers$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);

export const vargroups$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);

export const varlanguage$ = new BehaviorSubject<Language[] | undefined>(
  undefined
);

export const varstatus$ = new BehaviorSubject<Status[] | undefined>(undefined);
export const varVacationSubTypes$ = new BehaviorSubject<
  VacationSubtype[] | undefined
>(undefined);
export const varMeasureTypes$ = new BehaviorSubject<MeasureType[] | undefined>(
  undefined
);

export const vartranslations$ = new BehaviorSubject<any>({});

export const varchanneltypes$ = new BehaviorSubject<Lookup[] | undefined>(
  undefined
);

export const varnotificationtriggertypes$ = new BehaviorSubject<
  Lookup[] | undefined
>(undefined);

// export const varclients$ = new BehaviorSubject<Client[] | undefined>(undefined);

@Injectable({
  providedIn: 'root',
})
export class StartUpService {
  constructor(
    private ishtarOoOService: IshtarOoOService,
    private teamCalendarFacade: TeamCalendarFacade,
    private loaderService: LoaderService,
    private translationService: TranslationService
  ) {}

  getLicense() {
    this.ishtarOoOService.getLicense().subscribe((data) => {
      varlicense$.next(data);
    });
  }

  getAppinfo() {
    this.ishtarOoOService.getAppInfo().subscribe((data) => {
      varappinfo$.next(data);
    });
  }

  getLanguages() {
    this.ishtarOoOService.getLanguages().subscribe((data) => {
      varlanguage$.next(data);
    });
  }

  getUsers() {
    const userSubject = new Subject<void>();
    this.loaderService.startLoading('Loading user details', () => userSubject);
    this.ishtarOoOService.getUsers().subscribe((data) => {
      varusers$.next(data);
      userSubject.next();
    });
  }

  getGroups() {
    this.ishtarOoOService.getGroups().subscribe((data) => {
      vargroups$.next(data);
    });
  }

  getStatus() {
    this.ishtarOoOService.getStatus().subscribe((data) => {
      varstatus$.next(data);
    });
  }

  getVacationSubTypes() {
    this.ishtarOoOService.getVacationSubtypes().subscribe((data) => {
      varVacationSubTypes$.next(data);
    });
  }

  getMeasureTypes() {
    this.ishtarOoOService.getMeasureTypes().subscribe((data) => {
      varMeasureTypes$.next(data);
    });
  }

  getWorkRegimeDays() {
    return this.teamCalendarFacade.getWorkRegimeDays$();
  }
  getNotificationChannelTypes() {
    const channelSubject = new Subject<void>();
    this.loaderService.startLoading(
      'Loading notificationChannel details',
      () => channelSubject
    );
    return this.ishtarOoOService
      .getNotificationChannelTypes()
      .pipe(
        tap((data) => {
          varchanneltypes$.next(data);
          channelSubject.next();
        })
      )
      .subscribe();
  }

  getNotificationTriggerTypes() {
    const triggerSubject = new Subject<void>();
    this.loaderService.startLoading(
      'Loading notifcationTrigger details',
      () => triggerSubject
    );
    return this.ishtarOoOService
      .getNotificationTriggerTypes()
      .pipe(
        tap((data) => {
          varnotificationtriggertypes$.next(data);
          triggerSubject.next();
        })
      )
      .subscribe();
  }

  getTranslations() {
    this.ishtarOoOService.getTranslations().subscribe((data) => {
      vartranslations$.next(data);
    });
  }

  boot() {
    this.getLicense();
    return varlicense$.asObservable().pipe(
      filter((license) => !!license),
      first(),
      switchMap(() => {
        this.getAppinfo();
        this.getUsers();
        this.getGroups();
        this.getStatus();
        this.getLanguages();
        this.getVacationSubTypes();
        this.getMeasureTypes();
        this.getTranslations();
        this.getWorkRegimeDays();
        return forkJoin([
          vartranslations$.pipe(
            filter((t) => !!Object.keys(t).length),
            first()
          ),
          this.teamCalendarFacade.getTeamCalendars$(),
        ]);
      })
    );
  }
}
