<h3>Your Saldo</h3>
<processdelight-action-bar
  [iconActions]="iconActions"
  [navColor]="'#124464'"
  [navContrast]="'#fff'"
></processdelight-action-bar>

<processdelight-dashboard
  [data]="balancesForEmployee"
  [possibleColumns]="possibleColumns"
  [selectedColumns]="displayedColumns"
  [highlightOnHover]="true"
  [highlightSelected]="true"
  (sortChange)="sortChanged($event)"
  [sortColumn]="sortColumn"
  [sortDirection]="sortDirection"
>
</processdelight-dashboard>
