import { createAction, props } from '@ngrx/store';
import { VacationType } from '../../models/vacation-type';

export const getVacationTypes = createAction(
  '[VacationType] Get vacationTypes',
  props<{
    callback?: () => void;
  }>()
);

export const getVacationTypesResolved = createAction(
  '[VacationType] Get vacationTypes resolved',
  props<{
    vacationTypes: VacationType[];
  }>()
);

export const removeVacationTypes = createAction(
  '[VacationType] Remove vacationType',
  props<{ ids: string[]; callback?: () => void }>()
);

export const removeVacationTypesResolved = createAction(
  '[VacationType] Remove vacationType resolved',
  props<{ ishtarVacationTypeIds: string[] }>()
);

export const updateVacationTypes = createAction(
  '[VacationTypes] Update vacationTypes',
  props<{ vacationTypes: VacationType[]; callback?: () => void }>()
);

export const updateVacationTypesResolved = createAction(
  '[VacationTypes] Update vacationTypes resolved',
  props<{ updatedVacationTypes: VacationType[] }>()
);

export const addVacationTypes = createAction(
  '[VacationTypes] Add vacationTypes',
  props<{ vacationTypes: VacationType[]; callback?: (addedVacationTypes : VacationType[]) => void }>()
);

export const addVacationTypesResolved = createAction(
  '[VacationTypes] Add vacationTypes resolved',
  props<{ addedVacationTypes: VacationType[] }>()
);
