import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { varlicense$ } from './core/services/startup.service';
import { map, filter, first } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  MicrosoftAuthenticationGuard,
  GeneralLayoutComponent,
  SideBarAction,
  ContextMenuAction,
} from 'processdelight-angular-components';
import { CoreModule } from './core.module';
import {
  ActionBarComponent,
  LoaderComponent,
} from 'processdelight-angular-components';
import { MatDialog } from '@angular/material/dialog';
import { SaldoComponent } from './components/saldo/saldo.component';
@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CoreModule,
    RouterModule,
    GeneralLayoutComponent,
    ActionBarComponent,
    LoaderComponent,
  ],
})
export class AppComponent implements OnInit {
  title = 'IshtarOoO';

  orgLogo$ = varlicense$.pipe(map((o) => o?.logo));
  appLogo = environment.cdnAppIconUrl.replace('color', 'Blauw_Wit');

  userName$ = varlicense$.pipe(map((u) => u?.name));
  userPhoto$ = varlicense$.pipe(map((u) => u?.photo));

  navColor$ = varlicense$.pipe(map((u) => u?.navColor));
  navContrast$ = varlicense$.pipe(map((u) => u?.navContrast));
  constructor(private router: Router, private detailsDialog: MatDialog) {}

  actions: ContextMenuAction<unknown>[] = [
    new ContextMenuAction({
      label: 'Home',
      icon: 'home',
      iconOutline: true,
      isSymbol: true,
      action: () => this.router.navigate(['calender']),
    }),
    // new SideBarAction({
    //   title: 'Saldo',
    //   icon: 'saldo',
    //   iconOutline: true,
    //   route: ['saldoOverview'],
    // }),
  ];
  iconActions: ContextMenuAction<unknown>[] = [];
  ngOnInit(): void {
    varlicense$
      .pipe(
        filter((l) => !!l && !!Object.keys(l).length),
        first()
      )
      .subscribe((l) => {
        this.iconActions.push(
          new ContextMenuAction<unknown>({
            label: 'Saldo',
            icon: 'subject',
            iconOutline: true,
            action: () =>
              this.detailsDialog.open(SaldoComponent, {
                autoFocus: false,
                disableClose: false,
                minHeight: '50vh',
                maxHeight: '90vh',
              }),
          })
        );
        l?.licenses.some((i) => i.productName == 'Ishtar.OoO' && i.isAdmin)
          ? this.iconActions.push(
              new ContextMenuAction<unknown>({
                label: 'Settings',
                icon: 'settings',
                iconOutline: true,
                action: () => this.router.navigate(['settings', 'types']),
              })
            )
          : undefined;
      });
    const params = new URLSearchParams(location.search);
    if (params.has('appColor'))
      this.appLogo = environment.cdnAppIconUrl.replace(
        'color',
        params.get('appColor')!
      );
    const tabIconLink = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement;
    if (tabIconLink) tabIconLink.href = this.appLogo;
  }
}

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('processdelight-angular-components').then((m) => m.MsalModule),
  },
  {
    path: 'calender',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./components/calender/calender.component').then(
        (mod) => mod.CalenderComponent
      ),
    data: { breadcrumbTitle: 'Calender' },
  },
  {
    path: 'saldoOverview',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./components/saldo/saldo.component').then(
        (mod) => mod.SaldoComponent
      ),
    data: { breadcrumbTitle: 'SaldoOverview' },
  },
  {
    path: 'settings',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./components/settings/settings.component').then(
        (mod) => mod.SettingsComponent
      ),
    data: { breadcrumbTitle: 'Settings' },
    children: [
      {
        path: 'types',
        loadComponent: () =>
          import(
            './components/settings/vacation-type/vacation-type.component'
          ).then((mod) => mod.VacationTypeComponent),
        data: { breadcrumbTitle: 'Types' },
      },
      {
        path: 'mail',
        loadComponent: () =>
          import('./components/settings/mail/mail.component').then(
            (mod) => mod.MailComponent
          ),
        data: { breadcrumbTitle: 'Mail' },
      },
      {
        path: 'balance',
        loadComponent: () =>
          import('./components/settings/balance/balance.component').then(
            (mod) => mod.BalanceComponent
          ),
        data: { breadcrumbTitle: 'balance' },
      },
      {
        path: 'balance',
        loadComponent: () =>
          import('./components/settings/balance/balance.component').then(
            (mod) => mod.BalanceComponent
          ),
        data: { breadcrumbTitle: 'balance' },
      },
      // {
      //   path: 'products',
      //   loadComponent: () =>
      //     import('./settings/products/products.component').then(
      //       (mod) => mod.ProductsComponent
      //     ),
      //   data: { breadcrumbTitle: 'Products' },
      //   canDeactivate: [PreventNavigationGuard],
      // },
      // {
      //   path: 'default prices',
      //   loadComponent: () =>
      //     import('./settings/prices/prices.component').then(
      //       (mod) => mod.PricesComponent
      //     ),
      //   data: { breadcrumbTitle: 'Default prices' },
      //   canDeactivate: [PreventNavigationGuard],
      // },
      // {
      //   path: 'project types',
      //   loadComponent: () =>
      //     import('./settings/project-types/project-types.component').then(
      //       (mod) => mod.ProjectTypesComponent
      //     ),
      //   data: { breadcrumbTitle: 'Project types' },
      //   canDeactivate: [PreventNavigationGuard],
      // },
      // {
      //   path: 'budget library',
      //   loadComponent: () =>
      //     import('./settings/budget-library/budget-library.component').then(
      //       (mod) => mod.BudgetLibraryComponent
      //     ),
      //   data: { breadcrumbTitle: 'Budget library' },
      //   canDeactivate: [PreventNavigationGuard],
      // },
      // {
      //   path: 'general settings',
      //   loadComponent: () =>
      //     import('./settings/general/general.component').then(
      //       (mod) => mod.GeneralComponent
      //     ),
      //   data: { breadcrumbTitle: 'General settings' },
      //   canDeactivate: [PreventNavigationGuard],
      // },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

export const appName = 'IshtarOoO';
