import { DateTime } from 'luxon';
import { GroupUser } from 'processdelight-angular-components';

export class TeamCalendar {
  ishtarTeamCalendarId?: string;
  title!: string;
  vacationReason?: string;
  startDate!: Date;
  endDate!: Date;
  requester?: GroupUser;
  vacationType?: { id: string; name: string };
  approvalStatus?: { id: string; name?: string };

  constructor(obj: Partial<TeamCalendar>) {
    Object.assign(this, obj);
    // if (typeof this.startDate == 'string') {
    //   this.startDate = DateTime.fromFormat(
    //     this.startDate,
    //     'dd/MM/yyyy HH:mm',
    //     { zone: 'utc' }
    //   ).toJSDate();
    // }
    // if (typeof this.endDate == 'string') {
    //   this.endDate = DateTime.fromFormat(
    //     this.endDate,
    //     'dd/MM/yyyy HH:mm',
    //     { zone: 'utc' }
    //   ).toJSDate();
    // }
  }
}
