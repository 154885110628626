import { Action, createReducer, on } from '@ngrx/store';
import { MeasureType } from '../../models/measure-type';
import { AppState } from '../../../app.reducer';
import {
  getMeasureTypesResolved,
} from './measure-type.actions';

export const featureSlice = 'measureType';

export interface State {
    measureTypes: MeasureType[];
    measureType?: MeasureType;
    ishtarMeasuretypeIds?: string[];
}
const defaultState: State = {
    measureTypes: [],
    measureType: undefined,
    ishtarMeasuretypeIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return measureTypeReducer(state, action);
}

export const initialState: State = defaultState;

export const measureTypeReducer = createReducer(
  initialState,
  on(getMeasureTypesResolved, (state, { measureTypes }) => ({
    ...state,
    measureTypes: [...measureTypes],
  })),
);

export const measureTypeState = (state: AppState) =>
  state.coreFeature.measureType;
