import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarOoOService } from '../../services/ooo.service';
import {
  getEmailTemplates,
  getEmailTemplatesResolved,
  getUsers,
  getUsersResolved,
  updateEmailTemplates,
  updateEmailTemplatesResolved,
} from './email-template.actions';

@Injectable({ providedIn: 'root' })
export class EmailTemplateEffects {
  constructor(
    private actions$: Actions,
    private ishtarOoOService: IshtarOoOService
  ) {}

  getEmailTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(getEmailTemplates),
      switchMap(({ callback }) =>
        this.ishtarOoOService.getEmailTemplates().pipe(
          switchMap((emailtemplates) =>
            of(
              getEmailTemplatesResolved({
                emailTemplates: emailtemplates,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  updateEmailTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEmailTemplates),
      switchMap(({ emailTemplates }) =>
        this.ishtarOoOService.updateEmailTemplates(emailTemplates).pipe(
          switchMap((updatedEmailTemplates) =>
            of(updateEmailTemplatesResolved({ updatedEmailTemplates }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  getUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(getUsers),
      switchMap(({ callback }) =>
        this.ishtarOoOService.getUsers().pipe(
          switchMap((users) =>
            of(
              getUsersResolved({
                users: users,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );
}
