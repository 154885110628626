import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import * as fromVacationType from './vacation-type/vacation-type.reducer';
import { VacationTypeEffects } from './vacation-type/vacation-type.effects';
import * as fromTeamCalendar from './team-calendar/team-calendar.reducer';
import { TeamCalendarEffects } from './team-calendar/team-calendar.effects';
import * as fromVacationSubType from './vacation-subtype/vacation-subtype.reducer';
import { VacationSubtypeEffects } from './vacation-subtype/vacation-subtype.effects';
import * as fromMeasureType from './measure-type/measure-type.reducer';
import { MeasuretypeEffects } from './measure-type/measure-type.effects';
import * as fromVacationTypeTranslation from './vacation-typeTranslation/vacation-typeTranslation.reducer';
import { VacationTypeTranslationEffects } from './vacation-typeTranslation/vacation-typeTranslation.effects';
import * as fromBalance from './balance/balance.reducer';
import { BalanceEffects } from './balance/balance.effects';

import * as fromEmailTemplate from './email-template/email-template.reducer';
import { EmailTemplateEffects } from './email-template/email-template.effects';

import * as fromRequestConfiguration from './requestConfiguration/requestConfiguration.reducer';
import { RequestConfigurationEffects } from './requestConfiguration/requestConfiguration.effects';

export const featureKey = 'coreFeature';

export interface CoreState {
  [fromVacationType.featureSlice]: fromVacationType.State;
  [fromVacationSubType.featureSlice]: fromVacationSubType.State;
  [fromMeasureType.featureSlice]: fromMeasureType.State;
  [fromTeamCalendar.featureSlice]: fromTeamCalendar.State;
  [fromVacationTypeTranslation.featureSlice]: fromVacationTypeTranslation.State;
  [fromBalance.featureSlice]: fromBalance.State;
  [fromEmailTemplate.featureSlice]: fromEmailTemplate.State;
  [fromRequestConfiguration.featureSlice]: fromRequestConfiguration.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromVacationType.featureSlice]: fromVacationType.Reducer,
      [fromVacationSubType.featureSlice]: fromVacationSubType.Reducer,
      [fromMeasureType.featureSlice]: fromMeasureType.Reducer,
      [fromTeamCalendar.featureSlice]: fromTeamCalendar.Reducer,
      [fromVacationTypeTranslation.featureSlice]:
        fromVacationTypeTranslation.Reducer,
      [fromBalance.featureSlice]: fromBalance.Reducer,
      [fromEmailTemplate.featureSlice]: fromEmailTemplate.Reducer,
      [fromRequestConfiguration.featureSlice]: fromRequestConfiguration.Reducer,
    }),
  }
);
export const effects = [
  VacationTypeEffects,
  VacationSubtypeEffects,
  MeasuretypeEffects,
  TeamCalendarEffects,
  VacationTypeTranslationEffects,
  BalanceEffects,
  EmailTemplateEffects,
  RequestConfigurationEffects,
];
