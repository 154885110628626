import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarOoOService } from '../../services/ooo.service';
import {
  getVacationSubTypes,
  getVacationSubTypesResolved,
} from './vacation-subtype.actions';

@Injectable({ providedIn: 'root' })
export class VacationSubtypeEffects {
    constructor(
        private actions$: Actions,
        private ishtarOoOService: IshtarOoOService
    ) {}

    getVacationSubtypes = createEffect(() =>
        this.actions$.pipe(
            ofType(getVacationSubTypes),
            switchMap(({ callback }) =>
            this.ishtarOoOService.getVacationSubtypes().pipe(
                switchMap((vacationSubtypes) =>
                of(
                    getVacationSubTypesResolved({
                    vacationSubTypes: vacationSubtypes,  // Correct property name
                    })
                )
                ),
                tap(() => (callback ? callback() : undefined)),
                catchError((e) => [])
            ))
        )
    );
}
