import { Action, createReducer, on } from '@ngrx/store';
import { TeamCalendar } from '../../models/teamCalendar';
import { AppState } from '../../../app.reducer';
import {
  getTeamCalendarsResolved,
  removeTeamCalendarsResolved,
  updateTeamCalendarsResolved,
  addTeamCalendarsResolved,
  getWorkRegimeDaysResolved,
  updateWorkRegimeDaysResolved,
  addWorkRegimeDaysResolved,
} from './team-calendar.actions';
import { WorkRegimeDay } from '../../models/workRegimeDay';

export const featureSlice = 'teamCalendar';

export interface State {
  teamCalendars: TeamCalendar[];
  teamCalendar?: TeamCalendar;
  ishtarTeamCalendarIds?: string[];
  workRegimeDays: WorkRegimeDay[];
}
const defaultState: State = {
  teamCalendars: [],
  teamCalendar: undefined,
  ishtarTeamCalendarIds: [],
  workRegimeDays: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return teamCalendarReducer(state, action);
}

export const initialState: State = defaultState;

export const teamCalendarReducer = createReducer(
  initialState,
  on(getTeamCalendarsResolved, (state, { teamCalendars }) => ({
    ...state,
    teamCalendars: [...teamCalendars],
  })),
  on(removeTeamCalendarsResolved, (state, { ishtarTeamCalendarIds }) => ({
    ...state,
    teamCalendars: state.teamCalendars.filter(
      (s) => !ishtarTeamCalendarIds.includes(s.ishtarTeamCalendarId!)
    ),
  })),
  on(updateTeamCalendarsResolved, (state, { updatedTeamCalendars }) => ({
    ...state,
    teamCalendars: state.teamCalendars.map(
      (s) =>
        updatedTeamCalendars.find(
          (u) => s.ishtarTeamCalendarId === u.ishtarTeamCalendarId
        ) ?? s
    ),
  })),
  on(addTeamCalendarsResolved, (state, { addedTeamCalendars }) => ({
    ...state,
    teamCalendars: addedTeamCalendars.concat([...state.teamCalendars]),
  })),
  on(getWorkRegimeDaysResolved, (state, { result }) => ({
    ...state,
    workRegimeDays: [...result],
  })),
  on(updateWorkRegimeDaysResolved, (state, { updatedWorkRegimeDays }) => ({
    ...state,
    workRegimeDays: state.workRegimeDays?.map(
      (u) =>
        updatedWorkRegimeDays.find(
          (s) => u.ishtarWorkRegimeDayId === s.ishtarWorkRegimeDayId
        ) ?? u
    ),
  })),
  on(addWorkRegimeDaysResolved, (state, { addedWorkRegimeDays }) => ({
    ...state,
    workRegimeDays: addedWorkRegimeDays.concat([
      ...(state.workRegimeDays ?? []),
    ]),
  }))
);

export const teamCalendarState = (state: AppState) =>
  state.coreFeature.teamCalendar;
