import { createAction, props } from '@ngrx/store';
import { VacationSubtype } from '../../models/vacation-subtype';

export const getVacationSubTypes = createAction(
  '[VacationSubType] Get vacationSubTypes',
  props<{
    callback?: () => void;
  }>()
);

export const getVacationSubTypesResolved = createAction(
  '[VacationSubType] Get vacationSubTypes resolved',
  props<{
    vacationSubTypes: VacationSubtype[];
  }>()
);
